import * as React from 'react'
import Notification from 'rc-notification'
import { InfoCircleFilled, CheckCircleFilled, CloseCircleFilled, ExclamationCircleFilled } from '@ant-design/icons'

let defaultDuration = 3
let defaultTop
let messageInstance
const prefixCls = 'message'
let key = 1
let transitionName = 'move-up'
let getContainer
let maxCount

function getMessageInstance(callback) {
  if (messageInstance) {
    callback(messageInstance)
    return
  }
  Notification.newInstance(
    {
      prefixCls,
      transitionName,
      style: { top: defaultTop },
      getContainer,
      maxCount,
    },
    instance => {
      if (messageInstance) {
        callback(messageInstance)
        return
      }
      messageInstance = instance
      callback(instance)
    }
  )
}

function notice(args) {
  const duration = args.duration !== undefined ? args.duration : defaultDuration
  const icon = {
    info: <InfoCircleFilled style={{ color: '#1890ff' }} />,
    success: <CheckCircleFilled style={{ color: '#52c41a' }} />,
    error: <CloseCircleFilled style={{ color: '#f5222d' }} />,
    warning: <ExclamationCircleFilled style={{ color: '#faad14' }} />,
  }[args.type]

  // eslint-disable-next-line no-plusplus
  const target = args.key || key++
  const closePromise = new Promise(resolve => {
    const callback = () => {
      if (typeof args.onClose === 'function') {
        args.onClose()
      }
      return resolve(true)
    }
    getMessageInstance(instance => {
      const iconNode = icon
      const switchIconNode = iconNode
      instance.notice({
        key: target,
        duration,
        style: { textAlign: 'center' },
        content: (
          <div className="flex items-center justify-center">
            <div className="inline-block py-10 px-16 rounded shadow bg-white ">
              {args.icon ? args.icon : switchIconNode}
              <span>{args.content}</span>
            </div>
          </div>
        ),
        onClose: callback,
      })
    })
  })
  const result = () => {
    if (messageInstance) {
      messageInstance.removeNotice(target)
    }
  }
  result.then = (filled, rejected) => closePromise.then(filled, rejected)
  result.promise = closePromise
  return result
}

function isArgsProps(content) {
  return typeof content === 'object' && !!content.content
}

const api = {
  open: notice,
  config(options) {
    if (options.top !== undefined) {
      defaultTop = options.top
      messageInstance = null
    }
    if (options.duration !== undefined) {
      defaultDuration = options.duration
    }
    if (options.getContainer !== undefined) {
      getContainer = options.getContainer
    }
    if (options.transitionName !== undefined) {
      transitionName = options.transitionName
      messageInstance = null // delete messageInstance for new transitionName
    }
    if (options.maxCount !== undefined) {
      maxCount = options.maxCount
      messageInstance = null
    }
  },
  destroy() {
    if (messageInstance) {
      messageInstance.destroy()
      messageInstance = null
    }
  },
}

;['success', 'info', 'warning', 'error'].forEach(type => {
  api[type] = (content, duration, onClose) => {
    if (isArgsProps(content)) {
      return api.open({ ...content, type })
    }

    return api.open({ content, duration, type, onClose })
  }
})

export default api
