import 'lazysizes'
import React, { useEffect } from 'react'
import { ApolloProvider } from '@apollo/client'
import { client } from './src/graphql/client'

export const wrapRootElement = ({ element }) => {
  return <ApolloProvider client={client}>{element}</ApolloProvider>
}

// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => {
  return element
}
