exports.components = {
  "component---src-container-blog-detail-index-js": () => import("./../../../src/container/BlogDetail/index.js" /* webpackChunkName: "component---src-container-blog-detail-index-js" */),
  "component---src-container-product-detail-index-js": () => import("./../../../src/container/ProductDetail/index.js" /* webpackChunkName: "component---src-container-product-detail-index-js" */),
  "component---src-container-reset-password-index-js": () => import("./../../../src/container/ResetPassword/index.js" /* webpackChunkName: "component---src-container-reset-password-index-js" */),
  "component---src-container-verify-email-index-js": () => import("./../../../src/container/VerifyEmail/index.js" /* webpackChunkName: "component---src-container-verify-email-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-fake-js": () => import("./../../../src/pages/about-fake.js" /* webpackChunkName: "component---src-pages-about-fake-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-payment-js": () => import("./../../../src/pages/about-payment.js" /* webpackChunkName: "component---src-pages-about-payment-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-business-law-js": () => import("./../../../src/pages/business-law.js" /* webpackChunkName: "component---src-pages-business-law-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-copyright-js": () => import("./../../../src/pages/copyright.js" /* webpackChunkName: "component---src-pages-copyright-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-law-js": () => import("./../../../src/pages/law.js" /* webpackChunkName: "component---src-pages-law-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-mypage-js": () => import("./../../../src/pages/mypage.js" /* webpackChunkName: "component---src-pages-mypage-js" */),
  "component---src-pages-precautions-for-use-js": () => import("./../../../src/pages/precautions-for-use.js" /* webpackChunkName: "component---src-pages-precautions-for-use-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-returnedpolicy-js": () => import("./../../../src/pages/returnedpolicy.js" /* webpackChunkName: "component---src-pages-returnedpolicy-js" */),
  "component---src-pages-ship-policy-js": () => import("./../../../src/pages/ship-policy.js" /* webpackChunkName: "component---src-pages-ship-policy-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-under-maintenance-js": () => import("./../../../src/pages/under-maintenance.js" /* webpackChunkName: "component---src-pages-under-maintenance-js" */)
}

