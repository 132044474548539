import { ApolloClient, ApolloLink, createHttpLink, useApolloClient, gql } from '@apollo/client'
import { InMemoryCache } from '@apollo/client/cache'
import { AccountsGraphQLClient } from '@accounts/graphql-client'
import { AccountsClientPassword } from '@accounts/client-password'
import { AccountsClient } from '@accounts/client'
import fetch from 'isomorphic-fetch'
import { accountsLink } from '@accounts/apollo-link'
import { useEffect } from 'react'
import Cookie from 'js-cookie'
import { pick } from 'lodash'
import { onError } from '@apollo/client/link/error'
import notification from 'src/components/Notification'

export const GET_CART_ITEMS_GLOBAL = gql`
  query GetCartItems {
    cartItems @client(always: true) {
      _id
      name
      description
      detail
      price
      quantity
      images
    }
  }
`

export const GET_PAYMENT_FORM_GLOBAL = gql`
  query GetPaymentForm {
    paymentForm @client(always: true) {
      email
      name
      furigana
      postNo
      address
      subAddress
      phone
    }
  }
`

const httpLink = createHttpLink({
  uri: `${process.env.GATSBY_API_URL}/graphql`,
})
const store = new InMemoryCache()
const typeDefs = gql`
  extend type CartItem {
    _id: String
    name: String
    description: String
    detail: String
    price: Float
    quantity: Int
    images: [String]
  }

  extend type PaymentForm {
    _id: ID
    email: String
    name: String
    furigana: String
    postNo: String
    address: String
    subAddress: String
    phone: String
    city: String
  }

  extend type Query {
    paymentForm: PaymentForm
    cartItems: [CartItem]
    totalPriceInCart: Float
    totalQuantityInCart: Float
  }

  extend type Mutation {
    addIntoCart(cartItem: CartItem): CartItem
    removeProductInCart(_id: String): CartItem
    updatePaymentForm(paymentForm: PaymentForm): PaymentForm
  }
`

// accounts js
// export const graphQLApolloClient = new ApolloClient({
//   link: ApolloLink.from([httpLink]),
//   cache,
//   typeDefs
// })

// regular apollo client
// eslint-disable-next-line no-use-before-define
const authLink = accountsLink(() => accountsClient)

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors && graphQLErrors?.length > 0) {
    for (let err of graphQLErrors) {
      if (err?.exceptions?.some(exception => exception.name === 'paypal')) {
        return forward(operation)
      }
      switch (err.extensions.code) {
        // Apollo Server sets code to UNAUTHENTICATED
        // when an AuthenticationError is thrown in a resolver
        case 'UNAUTHENTICATED':
          return forward(operation)
        default: {
          return forward(operation)
        }
      }
    }
  }

  // To retry on network errors, we recommend the RetryLink
  // instead of the onError link. This just logs the error.
  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
  }
})

export const client = new ApolloClient({
  connectToDevTools: typeof window !== 'undefined' && (process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV) === 'development',
  ssrMode: typeof window === 'undefined',
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: typeof window !== 'undefined' ? store.restore(window.__APOLLO_STATE__) : store,
  fetch,
  typeDefs,
})

if (typeof window !== 'undefined') {
  client.addResolvers([
    {
      Query: {
        paymentForm: (_, _args, { cache }) => {
          const result = cache.readQuery({ query: GET_PAYMENT_FORM_GLOBAL })
          const { paymentForm } = result

          return paymentForm
        },
        cartItems: (_, _args, { cache }) => {
          const result = cache.readQuery({ query: GET_CART_ITEMS_GLOBAL })
          const { cartItems = [] } = result || { cartItems: [] }
          return cartItems
        },
        totalPriceInCart: (_, _args, { cache }) => {
          const result = cache.readQuery({ query: GET_CART_ITEMS_GLOBAL })
          const { cartItems = [] } = result || { cartItems: [] }
          return cartItems.reduce((total, item) => total + item.quantity * item.price, 0)
        },
        totalQuantityInCart: (_, _args, { cache }) => {
          const result = cache.readQuery({ query: GET_CART_ITEMS_GLOBAL })
          const { cartItems = [] } = result || { cartItems: [] }
          return cartItems.reduce((total, item) => total + item.quantity, 0)
        },
      },
      Mutation: {
        updatePaymentForm: (_, variables, { cache }) => {
          const { paymentForm: paymentFormRes } = variables
          const result = { __typename: 'PaymentForm', ...paymentFormRes }
          cache.writeQuery({
            query: GET_PAYMENT_FORM_GLOBAL,
            data: {
              paymentForm: result,
            },
          })
          return result
        },
        addIntoCart: (_root, variables, { cache }) => {
          const { cartItem } = variables
          const result = cache.readQuery({ query: GET_CART_ITEMS_GLOBAL })
          const { cartItems = [] } = result || { cartItems: [] }
          const newCartItems = cartItems?.map(item => (item._id === cartItem._id ? { ...item, ...cartItem } : item)) || []
          if (cartItems?.findIndex(item => item._id === cartItem._id) < 0) {
            newCartItems.push(cartItem)
          }

          cache.writeQuery({
            query: GET_CART_ITEMS_GLOBAL,
            data: {
              cartItems: newCartItems,
            },
          })

          if (typeof localStorage !== 'undefined') {
            setStore('cartItems', newCartItems)
          } else {
            setStore(
              'cartItems',
              newCartItems?.map(item => pick(item, ['_id', 'price', 'quantity', '__typename']))
            )
          }

          return cartItem
        },
        removeProductInCart: (_, { _id }, { cache }) => {
          const { cartItems } = cache.readQuery({ query: GET_CART_ITEMS_GLOBAL })
          cache.writeQuery({
            query: GET_CART_ITEMS_GLOBAL,
            data: {
              cartItems: cartItems?.filter(item => item._id !== _id) || [],
            },
          })
          setStore(
            'cartItems',
            cartItems.filter(item => item._id !== _id)
          )
        },
      },
      product: {
        isInCart: (product, _args, { cache }) => {
          const { cartItems } = cache.readQuery({ query: GET_CART_ITEMS_GLOBAL })
          return cartItems?.some(item => item._id === product._id)
        },
      },
    },
  ])
}

export const accountsGraphQL = new AccountsGraphQLClient({
  graphQLClient: client,
})
export const accountsClient = new AccountsClient({}, accountsGraphQL)
export const accountsPassword = new AccountsClientPassword(accountsClient)

const parseJSON = (source, defaulType = []) => {
  try {
    if (typeof window === 'undefined') return defaulType
    if (!source) return defaulType
    return JSON.parse(source)
  } catch (error) {
    return defaulType
  }
}

export const getStore = (key = 'cartItems') => {
  if (typeof localStorage !== 'undefined') return localStorage.getItem(key)
  return Cookie.get(key)
}

export const setStore = (key = 'cartItems', value, defaulType = []) => {
  if (typeof localStorage !== 'undefined') return localStorage.setItem(key, JSON.stringify(value || defaulType))
  return Cookie.set(key, JSON.stringify(value || defaulType), { expires: 3 })
}

export const removeStore = (key = 'cartItems') => {
  if (typeof localStorage !== 'undefined') return localStorage.removeItem(key)
  return Cookie.remove(key, { expires: 3 })
}

const cartItemsStorage = () => {
  if (typeof window === 'undefined') return []
  if (!getStore('cartItems')) return []
  return parseJSON(getStore('cartItems'))
}

export const useBrowserInit = () => {
  const data = {
    cartItems: [],
    paymentForm: null,
  }
  const client = useApolloClient()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      client.cache.writeQuery({
        query: GET_CART_ITEMS_GLOBAL,
        data: {
          cartItems: cartItemsStorage(),
        },
      })
      client.cache.writeQuery({
        query: GET_PAYMENT_FORM_GLOBAL,
        data: {
          paymentForm: data.paymentForm,
        },
      })

      client.onResetStore(() => {
        removeStore('cartItems')
        client.resetStore()
        // client.cache.writeQuery({
        //   query: GET_CART_ITEMS_GLOBAL,
        //   data: {
        //     cartItems: [],
        //   },
        // })
        // client.cache.writeQuery({
        //   query: GET_PAYMENT_FORM_GLOBAL,
        //   data: {
        //     paymentForm: null,
        //   },
        // })
      })
    }
  }, [])

  return {
    client,
  }
}
